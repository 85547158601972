







































































import useSelectItems from "@/use/selectItems";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    status: {
      type: String,
      required: false,
      default: "",
    },
  },

  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
  },

  setup(props, { root }) {
    const { shortLanguageItems } = useSelectItems({ root });
    const model = reactive<{
      emailEditor: any;
      title: string;
      englishEditor: any;
      englishTitle: string;
    }>({
      emailEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
      title: "",
      englishEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
      englishTitle: "",
    });

    const state = reactive({
      templates: [],
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      editorLoaded: false,
      englishEditorLoaded: false,
      templateAdded: {} as any,
      englishTemplateAdded: {} as any,
      dialog: false,
      event: {} as any,
      languageSwitch: "pl",
    });

    const messageId = computed(() => {
      if (props.status === "accept") {
        if (state.languageSwitch === "pl") {
          if (state.event && state.event.companyAcceptMail) {
            return state.event.companyAcceptMail.id;
          }
        } else if (state.languageSwitch === "en") {
          if (state.event && state.event.englishCompanyAcceptMail) {
            return state.event.englishCompanyAcceptMail.id;
          }
        }
      } else if (props.status === "reject") {
        if (state.languageSwitch === "pl") {
          if (state.event && state.event.companyRejectMail) {
            return state.event.companyRejectMail.id;
          }
        } else if (state.languageSwitch === "en") {
          if (state.event && state.event.englishCompanyRejectMail) {
            return state.event.englishCompanyRejectMail.id;
          }
        }
      } else return null;
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      if (props.status === "accept") {
        if (state.languageSwitch === "pl") {
          axiosInstance
            .get(`event/${root.$route.params.id}`)
            .then(({ data: { event } }) => {
              state.event = event;
              model.emailEditor.design =
                event.companyAcceptMail?.design || undefined;
              model.title = event.companyAcceptMail?.title || undefined;
              state.templateAdded = event.companyAcceptMail;
              state.editorLoaded = true;
              state.englishEditorLoaded = false;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              state.loaded = true;
              state.loading = false;
            });
        } else if (state.languageSwitch === "en") {
          axiosInstance
            .get(`event/${root.$route.params.id}`)
            .then(({ data: { event } }) => {
              state.event = event;
              model.emailEditor.design =
                event.englishCompanyAcceptMail?.design || undefined;
              model.englishTitle =
                event.englishCompanyAcceptMail?.title || undefined;
              state.englishTemplateAdded = event.companyAcceptMail;
              state.englishEditorLoaded = true;
              state.editorLoaded = false;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              state.loaded = true;
              state.loading = false;
            });
        }
      } else if (props.status === "reject") {
        if (state.languageSwitch === "pl") {
          axiosInstance
            .get(`event/${root.$route.params.id}`)
            .then(({ data: { event } }) => {
              state.event = event;
              model.emailEditor.design =
                event.companyRejectMail?.design || undefined;
              model.title = event.companyRejectMail?.title || undefined;
              state.templateAdded = event.companyRejectMail;
              state.editorLoaded = true;
              state.englishEditorLoaded = false;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              state.loaded = true;
              state.loading = false;
            });
        } else if (state.languageSwitch === "en") {
          axiosInstance
            .get(`event/${root.$route.params.id}`)
            .then(({ data: { event } }) => {
              state.event = event;
              model.emailEditor.design =
                event.englishCompanyRejectMail?.design || undefined;
              model.englishTitle =
                event.englishCompanyRejectMail?.title || undefined;
              state.englishTemplateAdded = event.englishCompanyRejectMail;
              state.englishEditorLoaded = true;
              state.editorLoaded = false;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              state.loaded = true;
              state.loading = false;
            });
        }
      }
    };

    onMounted(fetchEvent);
    watch(() => state.languageSwitch, fetchEvent);

    const createSuccess = () => {
      root.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "success",
        message: root.$tc("layout.misc.templateSaved"),
      });
      fetchEvent();
    };

    const updateSuccess = () => {
      root.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "success",
        message: root.$tc("layout.misc.templateEdited"),
      });
      fetchEvent();
    };

    const submitError = () => {
      root.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "error",
        message: getErrorMessage(state.error as number),
      });
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      if (props.status === "accept") {
        if (state.languageSwitch === "pl") {
          if (state.templateAdded) {
            const data = {
              template: model.emailEditor.html || undefined,
              design: model.emailEditor?.design || undefined,
              title: model.title || undefined,
            };

            const id = state.templateAdded?.id;
            axiosInstance
              .put(`message/${id}`, data, {
                headers: {
                  event: root.$route.params.id,
                },
              })
              .then(() => {
                state.success = true;
                state.error = false;
                updateSuccess();
              })
              .catch((error) => {
                state.error = error.response?.status;
                submitError();
              })
              .finally(() => (state.loading = false));
          } else {
            const data = {
              template: model.emailEditor.html || undefined,
              design: model.emailEditor?.design || undefined,
              title: model.title || undefined,
              relation: "eventCompanyAccept",
              eventId: root.$route.params.id,
            };
            axiosInstance
              .post("message", data, {
                headers: {
                  event: root.$route.params.id,
                },
              })
              .then(() => {
                state.success = true;
                state.error = false;
                createSuccess();
              })
              .catch((error) => {
                state.error = error.response?.status;
                submitError();
              })
              .finally(() => (state.loading = false));
          }
        }

        if (state.languageSwitch === "en") {
          if (state.englishTemplateAdded) {
            const data = {
              template: model.englishEditor.html || undefined,
              design: model.englishEditor?.design || undefined,
              title: model.englishTitle || undefined,
            };

            const id = state.englishTemplateAdded?.id;
            axiosInstance
              .put(`message/${id}`, data, {
                headers: {
                  event: root.$route.params.id,
                },
              })
              .then(() => {
                state.success = true;
                state.error = false;
                updateSuccess();
              })
              .catch((error) => {
                state.error = error.response?.status;
                submitError();
              })
              .finally(() => (state.loading = false));
          } else {
            const data = {
              template: model.englishEditor.html || undefined,
              design: model.englishEditor?.design || undefined,
              title: model.englishTitle || undefined,
              relation: "eventEnglishCompanyAccept",
              eventId: root.$route.params.id,
            };
            axiosInstance
              .post("message", data, {
                headers: {
                  event: root.$route.params.id,
                },
              })
              .then(() => {
                state.success = true;
                state.error = false;
                createSuccess();
              })
              .catch((error) => {
                state.error = error.response?.status;
                submitError();
              })
              .finally(() => (state.loading = false));
          }
        }
      } else if (props.status === "reject") {
        if (state.languageSwitch === "pl") {
          if (state.templateAdded) {
            const data = {
              template: model.emailEditor.html || undefined,
              design: model.emailEditor?.design || undefined,
              title: model.title || undefined,
            };

            const id = state.templateAdded?.id;
            axiosInstance
              .put(`message/${id}`, data, {
                headers: {
                  event: root.$route.params.id,
                },
              })
              .then(() => {
                state.success = true;
                state.error = false;
                updateSuccess();
              })
              .catch((error) => {
                state.error = error.response?.status;
                submitError();
              })
              .finally(() => (state.loading = false));
          } else {
            const data = {
              template: model.emailEditor.html || undefined,
              design: model.emailEditor?.design || undefined,
              title: model.title || undefined,
              relation: "eventCompanyReject",
              eventId: root.$route.params.id,
            };
            axiosInstance
              .post("message", data, {
                headers: {
                  event: root.$route.params.id,
                },
              })
              .then(() => {
                state.success = true;
                state.error = false;
                createSuccess();
              })
              .catch((error) => {
                state.error = error.response?.status;
                submitError();
              })
              .finally(() => (state.loading = false));
          }
        }
        if (state.languageSwitch === "en") {
          if (state.englishTemplateAdded) {
            const data = {
              template: model.englishEditor.html || undefined,
              design: model.englishEditor?.design || undefined,
              title: model.englishTitle || undefined,
            };

            const id = state.englishTemplateAdded?.id;
            axiosInstance
              .put(`message/${id}`, data, {
                headers: {
                  event: root.$route.params.id,
                },
              })
              .then(() => {
                state.success = true;
                state.error = false;
                updateSuccess();
              })
              .catch((error) => {
                state.error = error.response?.status;
                submitError();
              })
              .finally(() => (state.loading = false));
          } else {
            const data = {
              template: model.englishEditor.html || undefined,
              design: model.englishEditor?.design || undefined,
              title: model.englishTitle || undefined,
              relation: "eventEnglishCompanyReject",
              eventId: root.$route.params.id,
            };
            axiosInstance
              .post("message", data, {
                headers: {
                  event: root.$route.params.id,
                },
              })
              .then(() => {
                state.success = true;
                state.error = false;
                createSuccess();
              })
              .catch((error) => {
                state.error = error.response?.status;
                submitError();
              })
              .finally(() => (state.loading = false));
          }
        }
      }
    };

    return {
      model,
      state,
      onSubmit,
      getErrorMessage,
      shortLanguageItems,
      messageId,
    };
  },
});
